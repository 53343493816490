<template>
    <div class="navbarmobileFix">
     <nav class="d-none d-lg-block three-nav1">
         <ul class="three-nav1 navigation-list-item-blk" :class="{ 'show-navigation-list': seen }">
             <li class="three-nav1" @click="receiveConsultation()">
                 <div>
                     <img src="~@/assets/images1/doctor-hospital.png" style="width: 40px;">
                 </div>
             </li>
             <li class="three-nav1" @click="getCase()">
                 <div>
                     <img src="~@/assets/images/one-one.png" style="width: 40px;">
                 </div>
             </li>
             <li class="three-nav1" @click="offerConsultation()">
                 <div>
                     <img src="~@/assets/images/meet-icon.png" style="width: 40px;">
                 </div>
             </li>
             <li class="three-nav1" @click="joinPatient()">
                 <div>
                     <img src="~@/assets/images/patientgroup.png" style="width: 40px;">
                 </div>
             </li>
             <!-- <li class="three-nav1" @click="postConsultation()">
                 <div>
                     <img src="~@/assets/images/group.png" style="width: 40px;">
                 </div>
             </li> -->
         </ul>
     </nav>
 
     <section class="banner-text access-clinic">
         <div class="container text-center text-align">
                 <!-- FOR DESKTOP  -->
                     <div class="accessClinicalTextHeading d-none d-lg-block">
                         <h1 class="">Access clinical information </h1>
                     </div>
                      <!-- FOR MOBILE  -->
                     <div class="accessClinicalTextHeading d-block d-lg-none  ">
                         <h1 class="">Access clinical info</h1>
                     </div>
                 <div class="row">
                        <!-- FOR DESKTOP  -->
                     <div class="col-12 d-none d-lg-block banner-sub-content accessClinicalText">
                         <h3 class="">Access reliable and easy-to-read clinical resources for free.</h3>
 
                         <h3 class=" mt-4">Created and reviewed by doctors, they include reference and point-</h3>
                         <h3 class="">of-care resources. We support your efforts to equip your junior</h3>
                         <h3 class="">doctors in providing good patient care.</h3>
                     </div>
                      <!-- FOR MOBILE  -->
                     <div class="col-12 d-block d-lg-none banner-sub-content accessClinicalText">
                         <h3 class="mb-0">Access reliable and easy-to-</h3>
                         <h3 class="mb-0">read clinical resources for</h3>
                         <h3>free.</h3>
 
                         <h3 class=" mt-4 mb-0">Created and reviewed by</h3>
                         <h3 class="mb-0">doctors, they include</h3>
                         <h3 class="mb-0">reference and point-of-care</h3>
                         <h3>resources.</h3>
                     </div>
 
                 </div>
         </div>
     </section>
     <section class="banner-text mt-3">
         <div class=" text-center text-align">
                <!-- FOR DESKTOP  -->
                 <div class="sampleResourceText d-none d-lg-block">
             <h2 class="mb-0">Select the type of resource that interests </h2>
             <h2>you to view samples.</h2>
             <h2 class=" mt-4">To view the rest, please sign up.</h2>
         </div>
          <!-- FOR MOBILE  -->
         <div class="sampleResourceText d-block d-lg-none">
             <h2 class="mb-0">Select the type of</h2>
             <h2 class="mb-0">resource that interests</h2>
             <h2>you to view samples.</h2>
 
             <h2 class="mt-3 mb-0">To view the rest,</h2>
             <h2>please sign up.</h2>
         </div>
         <div class="row mt-4" style="margin: auto;">
             <div class="col-12">
             <select class="form-control drpdown" name="select" id="select" @change="dropdownSelect">
               <option value="spe">Select Resource</option>
               <option class="nav-item" v-for="(tab, index) in mainFolder" :value="tab" :key="index">
                         <a class="nav-link" href="javascript:void(0)">{{ tab.replace('/','',).replace('Standard Treatment Guidelines','Standard treatment guidelines').replace('dss-charts','Clinical decision charts').replace('illustrations','Illustrations').replace('icmr-stws','Standard treatment workflows')}}</a>
               </option>
             </select>
         </div> 
           </div>
                 <div class="row" style="margin: auto;">
                     <div class="col-12">
                         <select class="form-control drpdown" @change="resource" v-if="tabSelected" v-model="selectedStatus">
                             <option value="1">Select speciality</option>
                             <option v-for="(resource) in subFolderResources" :value="resource" :key=resource>{{resource.replace('/','')}}</option>
                         </select>
                     </div>
                 </div>
                 <div class="row mt-3 mb-5 ResourceOuter" v-if="source">
                     <div class="pagination" >
 
                 <div @click="fileImagesPdf(false)" :class="[idx === 0? 'pointerEventNull' : '']"
                         v-if="this.activeTab === 'icmr-stws/' || this.activeTab === 'Standard Treatment Guidelines/'">
                         <img  class="prevBtn" style="transform: rotate(180deg); width:100%;" src="@/assets/images/arrowhead.png"  > 
                 </div>
                 <div  @click="fileImagesPdf(false)" :class="[idx === 1? 'pointerEventNull' : '']"
                 v-if="this.activeTab === 'illustrations/' || this.activeTab === 'dss-charts/'">
                     <img class="prevBtn" style="transform: rotate(180deg);" src="@/assets/images/arrowhead.png"  >  
 
                 </div>
                 <div>
                     <h2>
                         {{ pageCount }}
                     </h2>
                 </div>
                 <div @click="fileImagesPdf(true)"  :class="[folders.length -1 == idx ? 'pointerEventNull' : '']">
                     <img class="nextBtn"  src="@/assets/images/arrowhead.png"  >  
                 </div>
 
                 </div>
                 <div class="mt-3 mb-3" style=" color:rgb(239, 63, 63)">
                     <h5>Documents appear in alphabetical order.</h5>
                 </div>
   <div class="card card-box-doc-library">
     <div class="card-body-libraryImg mt-3">
         <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
             <div class="carousel-inner" :class="[this.activeTab==='dss-charts/' ? 'zoomingImages' :'']" >
         <div class="carousel-item active" >
             <!-- <div class="mt-3" > -->
                 <div id="javascript:void(0)"  >
                     <!-- <iframe :src="source" class="pdfFiles" :class="[idx !==0 && idx !==1 ? 'blurFilesImages' : '']"
                     v-if=" this.activeTab === 'icmr-stws/'"></iframe> -->
                     <iframe :src="source" class="pdfFiles" :class="[idx !==0  ? 'blurFilesImages' : '']"
                         v-if="this.activeTab === 'Standard Treatment Guidelines/' ||this.activeTab === 'icmr-stws/' "></iframe>
 
                     <img :src="source" class="imgFiles" :class="[idx !==1 ? 'blurFilesImages' : '']"
                         v-if="this.activeTab === 'illustrations/' || this.activeTab === 'dss-charts/'" />
                 </div>
                 <!-- <div v-if="this.activeTab === 'icmr-stws/'">                                            
                     <button class="btn btn-blue-color text-white text-center"
                         v-on:click="clinicRes(5)" v-if="idx !== 0 && idx !==1" style="pointer-events: auto;">Sign up</button>
                 </div> -->
                 <div v-if=" this.activeTab === 'Standard Treatment Guidelines/' ||this.activeTab === 'icmr-stws/'">                                            
                     <button class="btn btn-blue-color text-white text-center signup-lib"
                         v-on:click="clinicRes(5)" v-if="idx !== 0" style="pointer-events: auto;">Sign up</button>
                 </div>
                 <div v-if="this.activeTab === 'illustrations/' || this.activeTab === 'dss-charts/'">
                         <button class="btn btn-blue-color text-white text-center signup-lib"
                         v-on:click="clinicRes(5)" v-if="idx !== 1" style="pointer-events: auto;">Sign up</button>
                 </div>
             <!-- </div> -->
         </div>
             </div>
             <button class="carousel-control-prev" type="button" @click="fileImagesPdf(false)" 
             v-if="this.activeTab === 'icmr-stws/' || this.activeTab === 'Standard Treatment Guidelines/'"
                 data-bs-target="#carouselExampleControls" data-bs-slide="prev"
                 :class="[idx === 0? 'pointerEventNull' : 'pointerAuto']">
                 <span class="carousel-control-prev-icon" :class="[idx===0? 'disableBtn': 'pointerAuto']"
                    ></span>
             </button>
             <button class="carousel-control-prev" type="button" @click="fileImagesPdf(false)"
             v-if="this.activeTab === 'illustrations/' || this.activeTab === 'dss-charts/'"
                 data-bs-target="#carouselExampleControls" data-bs-slide="prev"
                 :class="[idx === 1? 'pointerEventNull' : 'pointerAuto']">
                 <span class="carousel-control-prev-icon" :class="[idx===1? 'disableBtn': 'pointerAuto']"
                    ></span>
             </button>
 
             <button class="carousel-control-next" type="button" @click="fileImagesPdf(true)"
                 data-bs-target="#carouselExampleControls" data-bs-slide="next"
                 :class="[folders.length -1 == idx ? 'pointerEventNull' : 'pointerAuto']">
                 <span class="carousel-control-next-icon" :class="[folders.length -1 == idx ? 'disableBtn' : 'pointerAuto']"
                     ></span>
             </button>
         </div>
     </div>
 </div>
 </div>
         </div>
     </section>
   </div>
   <MobileNavbar></MobileNavbar>
 </template>
 <script>
 import { defineComponent } from "vue";
 import axios from 'axios'
 import MobileNavbar from "../common-mobile-footer.vue"
 export default defineComponent({
     components: {
         MobileNavbar
     },
     el: '#hide',
     data() {
         return {
             seen: true,
             clinicResource: true,
             currentPath: "",
             // issubmit: false,
             mainFolder: [],
             subFolder: [],
             folders: [],
             activeTab: null,
             prefixData: '',
             source: '',
             fileType: '',
             idx:0,
             tabSelected: false,
             showResource: false,
             subFolderResources:[],
             mainFolderResources:'',
             GetAllPracticeArea:[],
             selectedPracticeArea:{},
             PracticeId:[]
         };
     },
     created: function () {
         this.getMainFolder();
         this.getPracticArea();
         this.currentPath = this.$route.path.split('/')[2]
     },
     computed: {
         pageCount() {
   if (this.folders.length === 0) {
     return "0 of 0";
   }
 
   let currentPage;
   let totalPages;
 
   // Adjust the page count based on the starting index
   if (this.activeTab === 'illustrations/' || this.activeTab === 'dss-charts/') {
     currentPage = this.idx + 1 - 1; // Subtract 1 for starting from 1
     totalPages = this.folders.length - 1; // Subtract 1 for starting from 2
   } else {
     currentPage = this.idx + 1;
     totalPages = this.folders.length;
   }
 
   return `${currentPage} of ${totalPages}`;
 }
 
 },
     methods: {
         async getMainFolder() {
             const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
             await axios
                 .get(`${BASE_API_URL}/library/point`)
                 .then((response) => {
                     this.mainFolder = response.data.data.allKeys;
                     const icmr=this.mainFolder[0];
                     const stg=this.mainFolder[1];
                     const illustrations=this.mainFolder[2];
                     const dss = this.mainFolder[3];
                     this.mainFolder[3]=stg
                     this.mainFolder[2]=dss
                     this.mainFolder[1]=icmr
                     this.mainFolder[0]=illustrations
                     this.mainFolder.splice(this.mainFolder.indexOf(4))
                     this.mainFolder.splice(this.mainFolder.indexOf(3))
                 })
         },
         subFolderLists(type) {
             const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
             axios
                 .get(`${BASE_API_URL}/library/dss?mainFolder=${type}`)
                 .then((response) => {
                     this.subFolder = response.data.data.allKeys
                 })
         },
         dropdownSelect(event){
             const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
             this.tabSelected = true
             this.mainFolderResources=event.target.value;
             const subFiles = `${event.target.value}`
             this.activeTab = this.mainFolderResources;
             axios
             .get(`${BASE_API_URL}/library/dss?mainFolder=${subFiles}`)
                 .then((response) => {
                    this.subFolderResources=response.data.data.allKeys
                 })
                 if( this.selectedStatus){
                             this.source=false
                         }
             if(this.activeTab){
                 this.selectedStatus='1'; 
                     }  
         },
     resource(event) {
         const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
         this.prefixData = event.target.value;
         const subFiles = `${this.activeTab}${event.target.value}`
         axios
     .get(`${BASE_API_URL}/library/chart?prefix=${subFiles}`)
     .then((response) => {
         this.folders = response.data;
         this.folders = this.folders.map(url => {
             if (url.includes('#')) {
                 return url + '&toolbar=0&navpanes=0';
             } else {
                 return url + '#toolbar=0&navpanes=0';
             }
         });
         if(this.subFolder){
             this.idx=0;
         }                        
             if(this.activeTab === 'icmr-stws/' || this.activeTab === 'Standard Treatment Guidelines/'){
                 this.idx=0
                 this.source = this.folders[0] 
             }else if(this.activeTab === 'illustrations/' || this.activeTab === 'dss-charts/'){
                 this.idx=1
                 this.source = this.folders[1]
             }
         this.fileType = this.source.split('.')[1];
     })
     .catch(function (error) {
         return error
     })
     },
         fileImagesPdf(isSelect) {
             if (this.activeTab && this.prefixData) {
                 if (isSelect === true) {
                     this.idx++
                 } else if (isSelect === false) {
                     this.idx--
                 }
                 this.source = this.folders[this.idx]
                 this.fileType = this.source.split('.')[1];
             }
         },
         async getPracticArea() {
             const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
               return axios
                   .get(`${BASE_API_URL}/hcp-types?hcpTypes=doctor`).then(response => {
                     this.GetAllPracticeArea=response.data
                   })
           },
         async clinicRes(accessClinicID) {
             const practiceAreas =this.selectedStatus.replace('/','')
             this.selectedPracticeArea = this.GetAllPracticeArea.find(item => item["practiceArea"] === practiceAreas);
             const practiceAreaPayload={
                 PracticeId :JSON.stringify(this.selectedPracticeArea),
                 routeName : 'accessclinical',
                 card:'doctor',
                 doctorLibraryID: JSON.stringify(accessClinicID)
             }
             window.scrollTo(0, 0);
                 this.$router.push({ path: '/signup',query:practiceAreaPayload });
 
 
         },
         postConsultation() {
       window.scrollTo(0, 0);
      this.$router.push('/presign/postconsultation');
         },
         receiveConsultation() {
       window.scrollTo(0, 0);
      this.$router.push('/doctor/consultationrequests');
         },
         getCase() {
       window.scrollTo(0, 0);
      this.$router.push('/doctor/communities');
         },
         offerConsultation() {
       window.scrollTo(0, 0);
      this.$router.push('/doctor/offerconsultation');
         },
         joinPatient() {
       window.scrollTo(0, 0);
      this.$router.push( '/doctor/patientgroups');
         },
     },
     name: "HomePageB",
     props: {},
 });
 
 </script>
 <style>
   .pagination{
     display: flex;
     justify-content: center;
     align-items: center;
   }
 .pointerAuto{
     pointer-events: auto;
 }
 .ResourceOuter{
     margin-left:1.5rem;
     margin-right: 1.5rem;
     /* pointer-events: none; */
 }
  .card.card-box-doc-library {
     width: auto;
     margin: auto;
     border: 1px solid #000;
 }
 .accessClinicalTextHeading h1{
     font-weight: bold;
     text-align: center;
     margin-top: 20px;
     color: #000000;
 }
 
  .carousel-item {
     position: relative;
     display: none;
     /* float: left; */
     width: 100%;
     /* margin-right: -100%; */
     -webkit-backface-visibility: hidden;
     backface-visibility: hidden;
     transition: transform .6s ease-in-out;
 }
 .zoomingImages :hover{
     width: 950px;
     display: block;
     margin-left:auto;
     margin-right:auto;
 }
 .sampleResourceText{
     color:#000;
     margin-top: 3rem;
 }
 ::-webkit-scrollbar {
     display: none;
 }
 .carousel-control-prev-icon {
     background-color: #5f9ea0;
     display: inline-block;
     width: 6rem;
     height: 6rem;
     background-repeat: no-repeat;
     background-position: 50%;
     background-size: 100% 100%;
 }
 .prevBtn{
     cursor: pointer;
     width: 6rem;
     height: 6rem;
   }
   .nextBtn{
     cursor: pointer;
     width: 6rem;
     height: 6rem;
   }
 
   .carousel-control-next, .carousel-control-prev {
     opacity: 1;
   }
 
 .carousel-control-next-icon {
     background-color: #5f9ea0;
     display: inline-block;
     width: 6rem;
     height: 6rem;
     background-repeat: no-repeat;
     background-position: 50%;
     background-size: 100% 100%;
 }
 
 .signup-lib {
     width: 150px !important;
     position: absolute;
     top: 418px;
     right: 374px;
     height: 60px;
     font-weight: bold;
     font-size: 25px;
 }
 
 .blurFilesImages {
     filter: blur(8px);
 }
 
 .pointerEventNull {
     pointer-events: none;
     opacity: 0.2;
 }
 
 .imgFiles {
     width: 900px;
     height:999px;
 
 }
 
 .pdfFiles {
     width: 900px;
     height: 999px;
 }
 
 .nav-item.active .nav-link {
     background-color: #34989f;
     color: #fff;
 }
 .navigation-list-item-blk {
     transition: 0.3s;
     /* transform: translateX(-100px); */
     transform: translateX(75px);
 }
 
 .show-navigation-list {
     transform: translateX(0px);
 }
 
 .btn-outline-primary {
     color: #0d6efd;
     border-color: #0d6efd;
     border-radius: 0px !important;
 }
 
 .rxix-menu li.nav-item a.nav-link {
     color: #00979e;
 }
 
 nav.navbar.navbar-expand-lg.navbar-light {
     position: fixed;
     width: 100%;
     z-index: 9999;
     top: 0;
 }
 
 
 section.banner-text.access-clinic {
     margin-top: 4.5rem;
     background-repeat: no-repeat;
     background-image: url("~@/assets/images/cards-banner7.png");
     background-size: 3500px 593px;
     /* transform: rotate(90deg);  */
     padding: 30px 0px 50px 0px;
 }
 
 
 .banner-sub-content {
     margin-top: 20px;
 }
 
 select.form-control.drpdown {
     cursor: pointer;
     width: 400px;
     font-size: 20px;
     margin-left: auto;
     margin-right: auto;
     text-align: left;
     margin-bottom:5px;
 
 }
 
 button.presign.btn.btn-blue-color.text-white {
     float: right;
     width: 40%;
     /* width: auto; */
     font-weight: bold;
     font-size: large;
     /* margin: 10px -60px 10px 0px; */
 }
 
 button.btn.btn-action {
     background: #00979e;
     color: #fff;
     width: 100%;
     border-radius: inherit;
 }
 
 .col-box {
     display: flex;
     justify-content: center;
     margin: 40px 40px 0px 0px
 }
 
 @media screen and (max-width:991px) {
     .col-box {
         display: flex;
         margin: auto;
     }
 }
 
 .btn-text {
     text-align: center;
     margin: 25px;
     padding: 25px;
     color: #00979e;
 }
 
 @media screen and (max-width:991px) {
     .btn-text {
         text-align: center;
         margin: 20px;
         padding: 20px;
         color: #00979e;
     }
 }
 
 
 button.btn.btn-blue-color.text-white {
     width: 100%;
 }
 
 button.offer.btn.btn-blue-color.text-white {
     width: auto;
     font-weight: bold;
     font-size: large;
     margin-bottom: 30px;
 }
 .card-body-libraryImg {
     padding:0;
 }
 
 .nav-tabs {
     border-bottom: 0px !important;
 }
 
 
 *,
 *:before,
 *:after {
     box-sizing: border-box;
 }
 
 :after {
     content: "";
 }
 
 /* nav.three-nav1 {
     float: right;
     position: relative;
     top: 52px;
     left: 70px;
     background: transparent;
 } */
 nav.three-nav1 {
     float: right;
     position: fixed;
     right: -3px;
     background: transparent;
 }
 
 nav.three-nav1:hover {
     transform: perspective(400px) rotateY(1deg);
 }
 
 nav.three-nav1 ul.three-nav1 {
     text-align: center;
     /* margin-top: 60px; */
     margin-top: 30px;
 }
 
 @media screen and (max-width:992px) {
     select.form-control.drpdown {
     width: 300px;
     font-size: 18px;
     }
     nav.three-nav1 ul.three-nav1 {
         text-align: center;
         /* margin-left: -72px; */
         margin-top: 0px
             /* margin-top: 77px; */
     }
 }
 
 nav.three-nav1 ul.three-nav1 li.three-nav1 {
     position: relative;
     width: 71px;
     cursor: pointer;
     background: cadetblue;
     text-transform: uppercase;
     transition: all .4s ease-out;
     list-style: none;
 }
 
 nav.three-nav1 ul.three-nav1 li:after {
     position: absolute;
     background: white;
     color: crimson;
     top: 0;
     /* right: 70px; */
     left: 0px;
     width: 70px;
     height: 100%;
     opacity: .5;
     transform: perspective(400px) rotateY(90deg);
     transform-origin: 0 100%;
     transition: all .4s ease-out;
 }
 
 nav.three-nav1 ul.three-nav1 li:nth-child(1):after {
     /* content: "dcba"; */
     line-height: 88px;
 }
 
 nav.three-nav1 ul.three-nav1 li:nth-child(2):after {
     /* content: "get"; */
     line-height: 88px;
 }
 
 nav.three-nav1 ul.three-nav1 li:nth-child(3):after {
     /* content: "offer"; */
     line-height: 85px;
 }
 
 nav.three-nav1 ul.three-nav1 li:nth-child(4):after {
     /* content: "join"; */
     line-height: 70px;
 }
 
 nav.three-nav1 ul.three-nav1 li:nth-child(5):after {
     /* content: "post"; */
     line-height: 85px;
 }
 
 nav.three-nav1 ul.three-nav1 li:hover {
     /* transform: translateX(0px); */
     /* transform: translateX(70px); */
     /* transform: rotateY(-360deg); */
     transform: translateX(0px);
     background-color: #fff;
 }
 
 nav.three-nav1 ul.three-nav1 li:hover:after {
     opacity: 1;
     /* transform: perspective(400px) rotateY(0deg) scale(1); */
     /* transform: perspective(400px) rotateY(180deg) scale(1); */
     transform: perspective(400px) rotateY(90deg) scale(1);
 }
 
 
 nav.three-nav1 ul.three-nav1 li>div {
     display: inline-block;
     padding: 25px 0;
     background: transparent;
 }
 
 nav.three-nav1 ul.three-nav1 li.three-nav1 div {
     position: relative;
 }
 
 
 li.navbar-nav-list.hidden-md.hidden-lg.dropdown.xs-design.open-side-menu1 {
     list-style: none;
     position: relative;
     left: 35px;
     bottom: 20px;
     /* right: 35px; */
     /* top: 200px; */
 }
 
 .dropdown-toggle::after {
     display: inline-block;
     margin-left: 0.255em;
     vertical-align: 0.255em;
     content: "";
     border-top: 0 solid !important;
     border-right: 0.3em solid transparent;
     border-bottom: 0;
     border-left: 0.3em solid transparent;
 }
 @media screen and (max-width:991px) {
     .card.card-box-doc-library {
         width: 100%;
     }
     .pdfFiles {
     width:100%;
     height:900px;
 }
 .imgFiles {
     width:100%;
     height:900px;
 }
 .signup-lib {
     width: 120px;
     position: absolute;
     top: 337px;
     right: 350px;
     height: 53px;
     font-weight: bold;
     font-size: 21px;
 }
 .carousel-control-next-icon {
     width: 5rem;
     height:5rem;
 }
 .carousel-control-prev-icon {
     width: 5rem;
     height:5rem;
 }
 .prevBtn{
     width: 5rem;
     height: 5rem;
   }
   .nextBtn{
     width: 5rem;
     height: 5rem;
   }
 }
 @media screen and (max-width:991px) {
     .navbarmobileFix{
     margin-bottom: 7rem;
   }
     section.banner-text.access-clinic {
         margin-top: 0px;
         padding: 30px 0px 38px 0px;
     }
     .accessClinicalTextHeading h1{
         font-size: 33px;
     }
     .accessClinicalText h3 {
         font-size:22px;
     }
     .sampleResourceText h2{
         font-size: 28px;
     }
     .sampleResourceText {
         margin-top: 2rem;
     }
 }
 @media screen and (max-width:768px) {
     .pdfFiles {
     width:100%;
     height:650px;
 }
 .imgFiles {
     width:100%;
     height:650px;
 }
 .signup-lib {
     width: 120px;
     position: absolute;
     top: 280px;
     right: 180px;
     height: 53px;
     font-weight: bold;
     font-size: 21px;
 }
 .carousel-control-next-icon {
     width: 4rem;
     height:4rem;
 }
 .carousel-control-prev-icon {
     width: 4rem;
     height:4rem;
 }
 .prevBtn{
     width: 4rem;
     height: 4rem;
   }
   .nextBtn{
     width: 4rem;
     height: 4rem;
   }
 
     select.form-control.drpdown {
     width: 300px;
     font-size: 16px;
     }
 }
 @media screen and (max-width:576px) {
     .pdfFiles {
     width:100%;
     height:500px;
 }
 .imgFiles {
     width:100%;
     height:500px;
 }
 .signup-lib {
     width: 100px;
     position: absolute;
     top: 210px;
     right: 120px;
     height: 49px;
     font-weight: bold;
     font-size: 19px;
 }
 .accessClinicalTextHeading h1{
         font-size: 25px;
     }
     .accessClinicalText h3 {
         font-size:17px;
     }
     .sampleResourceText h2{
         font-size: 21px;
     }
     .sampleResourceText {
         margin-top: 1.5rem;
     }
 }
 
 @media screen and (max-width:550px) {
 /* .banner-sub-content {
     margin-top: 10px;
 } */
 section.banner-text.access-clinic {
     padding: 10px 0px 24px 0px;
 }
     select.form-control.drpdown {
     width: 200px;
     font-size: 14px;
     }
 }
 @media screen and (max-width:450px) {
 
 .signup-lib {
     width: 85px;
     position: absolute;
     top: 181px;
     right: 110px;
     left: auto;
     height: 49px;
     font-weight: bold;
     font-size: 16px;
 }
 .carousel-control-next-icon {
     width: 3rem;
     height:3rem;
 }
 .carousel-control-prev-icon {
     width: 3rem;
     height:3rem;
 }
 .prevBtn{
     width: 3rem;
     height: 3rem;
   }
   .nextBtn{
     width: 3rem;
     height: 3rem;
   }
 .pdfFiles {
     width:100%;
     height:400px;
 }
 .imgFiles {
     width:100%;
     height:400px;
 }
 }
 @media screen and (max-width:390px) {
     .pdfFiles {
     width:100%;
     height:350px;
 }
 .imgFiles {
     width:100%;
     height:350px;
 }
 .signup-lib {
     width: 84px;
     position: absolute;
     top: 158px;
     right: 81px;
     left: auto;
     height: 40px;
     font-weight: bold;
     font-size: 15px;
 }
 }
 </style>